// Fungsi untuk menyimpan data dalam local storage dengan waktu kedaluwarsa
export const setCache = (key, value, expirationTimeInMilliseconds) => {
  const expirationTime = Date.now() + expirationTimeInMilliseconds;
  localStorage.setItem(key, JSON.stringify({ value, expirationTime }));
};

// Fungsi untuk mendapatkan data dari local storage jika masih berlaku
export const getCache = (key) => {
  const cachedItem = localStorage.getItem(key);
  if (cachedItem) {
    const { value, expirationTime } = JSON.parse(cachedItem);
    if (Date.now() < expirationTime) {
      return value;
    }
    // Hapus item dari cache jika telah kedaluwarsa
    localStorage.removeItem(key);
  }
  return null;
};

// Fungsi untuk menghapus data dari local storage
export const removeCache = (key) => {
  localStorage.removeItem(key);
};
