import { lazy } from 'react';
import { getCache } from 'helpers/local-storage';
import Loadable from 'component/Loadable';
import { Navigate } from 'react-router';

const AuthLogin = Loadable(lazy(() => import('views/Login')));

// ==============================|| AUTHENTICATION ROUTES ||============================== //

const accessToken = getCache('access_token');

const AuthenticationRoutes = {
  path: '/',
  children: [
    {
      index: true,
      element: accessToken ? <Navigate to="/dashboard" /> : <AuthLogin />
    },
    {
      path: 'login',
      element: accessToken ? <Navigate to="/dashboard" /> : <AuthLogin />
    }
  ]
};

export default AuthenticationRoutes;
