export const getUuid = () => {
  const uuid = localStorage.getItem('uuid');

  if (uuid) {
    return uuid;
  }

  const newUuid = crypto.randomUUID();
  localStorage.setItem('uuid', newUuid);

  return newUuid;
};
