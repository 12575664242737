import { lazy } from 'react';
import { Navigate } from 'react-router';
import { getCache } from 'helpers/local-storage';
import Loadable from 'component/Loadable';
import MainLayout from 'layout/MainLayout';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard')));
const GraduateStudent = Loadable(lazy(() => import('views/GraduateStudent')));
const GraduationBatch = Loadable(lazy(() => import('views/GraduationBatch')));
const FormGraduationBatch = Loadable(lazy(() => import('views/GraduationBatch/form')));
const Redeem = Loadable(lazy(() => import('views/Redeem')));
const MenuGroup = Loadable(lazy(() => import('views/Menu/Group')));
const FormMenuGroup = Loadable(lazy(() => import('views/Menu/Group/form')));
const MenuList = Loadable(lazy(() => import('views/Menu/List')));
const FormMenuList = Loadable(lazy(() => import('views/Menu/List/form')));
const Faq = Loadable(lazy(() => import('views/Faq')));
const FormFaq = Loadable(lazy(() => import('views/Faq/form')));
const Article = Loadable(lazy(() => import('views/Article')));
const FormArticle = Loadable(lazy(() => import('views/Article/form')));
const Rule = Loadable(lazy(() => import('views/Rule')));
const FormRule = Loadable(lazy(() => import('views/Rule/form')));
const Schedule = Loadable(lazy(() => import('views/Schedule')));
const FormSchedule = Loadable(lazy(() => import('views/Schedule/form')));
const ScheduleStudent = Loadable(lazy(() => import('views/ScheduleStudent')));
const FormScheduleStudent = Loadable(lazy(() => import('views/ScheduleStudent/form')));
const Letter = Loadable(lazy(() => import('views/Letter')));
const FormLetter = Loadable(lazy(() => import('views/Letter/form')));
const GradeCode = Loadable(lazy(() => import('views/GradeCode')));
const FormGradeCode = Loadable(lazy(() => import('views/GradeCode/form')));
const AcademicCalendar = Loadable(lazy(() => import('views/AcademicCalendar')));
const FormAcademicCalendar = Loadable(lazy(() => import('views/AcademicCalendar/form')));
const Room = Loadable(lazy(() => import('views/Room')));
const FormRoom = Loadable(lazy(() => import('views/Room/form')));
const PaymentMethod = Loadable(lazy(() => import('views/PaymentMethod')));
const FormPaymentMethod = Loadable(lazy(() => import('views/PaymentMethod/form')));
const GraduationLevel = Loadable(lazy(() => import('views/GraduationLevel')));
const FormGraduationLevel = Loadable(lazy(() => import('views/GraduationLevel/form')));
const LectureSubject = Loadable(lazy(() => import('views/LectureSubject')));
const FormLectureSubject = Loadable(lazy(() => import('views/LectureSubject/form')));
const Faculty = Loadable(lazy(() => import('views/Faculty')));
const Major = Loadable(lazy(() => import('views/Major')));
const StudyProgram = Loadable(lazy(() => import('views/StudyProgram')));
const Subject = Loadable(lazy(() => import('views/Subject')));
const FormSubject = Loadable(lazy(() => import('views/Subject/form')));
const SurveyModule = Loadable(lazy(() => import('views/SurveyModule')));
const FormSurveyModule = Loadable(lazy(() => import('views/SurveyModule/form')));
const SurveyPeriod = Loadable(lazy(() => import('views/SurveyPeriod')));
const FormSurveyPeriod = Loadable(lazy(() => import('views/SurveyPeriod/form')));
const SurveyPeriodQuestion = Loadable(lazy(() => import('views/SurveyPeriod/Question')));
const FormSurveyPeriodQuestion = Loadable(lazy(() => import('views/SurveyPeriod/Question/form')));
const Question = Loadable(lazy(() => import('views/Question')));
const FormQuestion = Loadable(lazy(() => import('views/Question/form')));
const QuestionChoice = Loadable(lazy(() => import('views/Question/Choice')));
const FormQuestionChoice = Loadable(lazy(() => import('views/Question/Choice/form')));
const QuestionAnswer = Loadable(lazy(() => import('views/QuestionAnswer')));
const AcademicUserType = Loadable(lazy(() => import('views/Academic/UserType')));
const FormAcademicUserType = Loadable(lazy(() => import('views/Academic/UserType/form')));
const AcademicMenuType = Loadable(lazy(() => import('views/Academic/MenuType')));
const FormAcademicMenuType = Loadable(lazy(() => import('views/Academic/MenuType/form')));
const Attendance = Loadable(lazy(() => import('views/Attendance')));

// ==============================|| MAIN ROUTES ||============================== //

const accessToken = getCache('access_token');
const email = getCache('email');

const renderElement = () => {
  if (accessToken) {
    return <MainLayout />;
  } else {
    return <Navigate to="/login" />;
  }
};

const renderPages = () => {
  if (email == 'emy@uimobile.net') {
    return [
      { path: 'dashboard', element: <DashboardDefault /> },
      { path: 'attendance', children: [{ index: true, element: <Attendance /> }] },
      { path: 'question-answer', children: [{ index: true, element: <QuestionAnswer /> }] }
    ];
  } else {
    return [
      { path: 'dashboard', element: <DashboardDefault /> },
      { path: 'graduate-student', element: <GraduateStudent /> },
      {
        path: 'graduation-batch',
        children: [
          { index: true, element: <GraduationBatch /> },
          { path: ':type/:id?', element: <FormGraduationBatch /> }
        ]
      },
      { path: 'redeem', element: <Redeem /> },
      {
        path: 'menu',
        children: [
          {
            path: 'group',
            children: [
              { index: true, element: <MenuGroup /> },
              { path: ':type/:id?', element: <FormMenuGroup /> }
            ]
          },
          {
            path: 'list',
            children: [
              { index: true, element: <MenuList /> },
              { path: ':type/:id?', element: <FormMenuList /> }
            ]
          }
        ]
      },
      {
        path: 'schedule-student',
        children: [
          { index: true, element: <ScheduleStudent /> },
          { path: ':type/:id?', element: <FormScheduleStudent /> }
        ]
      },
      {
        path: 'schedule',
        children: [
          { index: true, element: <Schedule /> },
          { path: ':type/:id?', element: <FormSchedule /> }
        ]
      },
      {
        path: 'academic-calendar',
        children: [
          { index: true, element: <AcademicCalendar /> },
          { path: ':type/:id?', element: <FormAcademicCalendar /> }
        ]
      },
      {
        path: 'room',
        children: [
          { index: true, element: <Room /> },
          { path: ':type/:id?', element: <FormRoom /> }
        ]
      },
      {
        path: 'faq',
        children: [
          { index: true, element: <Faq /> },
          { path: ':type/:id?', element: <FormFaq /> }
        ]
      },
      {
        path: 'article',
        children: [
          { index: true, element: <Article /> },
          { path: ':type/:id?', element: <FormArticle /> }
        ]
      },
      {
        path: 'rule',
        children: [
          { index: true, element: <Rule /> },
          { path: ':type/:id?', element: <FormRule /> }
        ]
      },
      {
        path: 'letter',
        children: [
          { index: true, element: <Letter /> },
          { path: ':type/:id?', element: <FormLetter /> }
        ]
      },
      {
        path: 'grade-code',
        children: [
          { index: true, element: <GradeCode /> },
          { path: ':type/:id?', element: <FormGradeCode /> }
        ]
      },
      {
        path: 'payment-method',
        children: [
          { index: true, element: <PaymentMethod /> },
          { path: ':type/:id?', element: <FormPaymentMethod /> }
        ]
      },
      {
        path: 'graduation-level',
        children: [
          { index: true, element: <GraduationLevel /> },
          { path: ':type/:id?', element: <FormGraduationLevel /> }
        ]
      },
      {
        path: 'lecture-subject',
        children: [
          { index: true, element: <LectureSubject /> },
          { path: ':type/:id?', element: <FormLectureSubject /> }
        ]
      },
      {
        path: 'faculty',
        children: [
          { index: true, element: <Faculty /> },
          {
            path: ':id_faculty/major',
            children: [
              { index: true, element: <Major /> },
              { path: ':id_major/study-program', element: <StudyProgram /> }
            ]
          }
        ]
      },
      {
        path: 'subject',
        children: [
          { index: true, element: <Subject /> },
          { path: ':type/:id?', element: <FormSubject /> }
        ]
      },
      {
        path: 'survey-module',
        children: [
          { index: true, element: <SurveyModule /> },
          { path: ':type/:id?', element: <FormSurveyModule /> }
        ]
      },
      {
        path: 'survey-period',
        children: [
          { index: true, element: <SurveyPeriod /> },
          { path: ':type/:id?', element: <FormSurveyPeriod /> },
          {
            path: ':survey_period_id/question',
            children: [
              { index: true, element: <SurveyPeriodQuestion /> },
              { path: ':type/:id?', element: <FormSurveyPeriodQuestion /> }
            ]
          }
        ]
      },
      {
        path: 'question',
        children: [
          { index: true, element: <Question /> },
          { path: ':type/:id?', element: <FormQuestion /> },
          {
            path: ':question_id/choice',
            children: [
              { index: true, element: <QuestionChoice /> },
              { path: ':type/:id?', element: <FormQuestionChoice /> }
            ]
          }
        ]
      },
      { path: 'question-answer', children: [{ index: true, element: <QuestionAnswer /> }] },
      {
        path: 'academic',
        children: [
          {
            path: 'user-type',
            children: [
              { index: true, element: <AcademicUserType /> },
              { path: ':type/:id?', element: <FormAcademicUserType /> }
            ]
          },
          {
            path: 'menu-type',
            children: [
              { index: true, element: <AcademicMenuType /> },
              { path: ':type/:id?', element: <FormAcademicMenuType /> }
            ]
          }
        ]
      },
      { path: 'attendance', children: [{ index: true, element: <Attendance /> }] }
    ];
  }
};

const MainRoutes = {
  path: '/',
  element: renderElement(),
  children: renderPages()
};

export default MainRoutes;
