import { NavLink } from 'react-router-dom';
import { useDialogErrorStore } from 'store/storeDialogError';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const DialogError = () => {
  const { dialogError, setDialogError } = useDialogErrorStore();

  const handleClick = () => {
    setDialogError({ open: false, message: '', urlBack: '' });
  };

  return (
    <Dialog open={dialogError.open} maxWidth="xs" fullWidth>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1.5 }}>
        <CancelRoundedIcon sx={{ fontSize: '100px', color: '#d32f2f' }} />
        <Typography variant="h5">Oops...</Typography>
        <Typography variant="body1" textAlign="center">
          {dialogError.message}
        </Typography>
        <NavLink to={dialogError.urlBack}>
          <Button variant="outlined" color="secondary" sx={{ my: 1.5 }} onClick={handleClick}>
            Kembali
          </Button>
        </NavLink>
      </DialogContent>
    </Dialog>
  );
};

export default DialogError;
