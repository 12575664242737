import { create } from 'zustand';

export const useAlertStore = create((set) => ({
  open: false,
  setOpen: (open) => set({ open }),
  successMessage: '',
  setSuccessMessage: (successMessage) => set({ successMessage }),
  errorMessage: '',
  setErrorMessage: (errorMessage) => set({ errorMessage })
}));
