import { create } from 'zustand';

export const useDialogErrorStore = create((set) => ({
  dialogError: {
    open: false,
    message: '',
    urlBack: ''
  },
  setDialogError: (dialogError) => set((state) => ({ dialogError: { ...state.dialogError, ...dialogError } }))
}));
