import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import { getCache } from 'helpers/local-storage';

const email = getCache('email');

const renderMenuItems = () => {
  if (email == 'emy@uimobile.net') {
    return [
      {
        id: 'navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: HomeOutlinedIcon,
            url: '/dashboard'
          }
        ]
      },
      {
        id: 'survey',
        type: 'group',
        title: 'PKKMB',
        children: [
          {
            id: 'question-answer',
            title: 'Hasil Evaluasi',
            type: 'item',
            icon: QuestionAnswerOutlinedIcon,
            url: '/question-answer'
          },
          {
            id: 'attendance',
            title: 'Kehadiran',
            type: 'item',
            icon: ContactMailOutlinedIcon,
            url: '/attendance'
          }
        ]
      }
    ];
  } else {
    return [
      {
        id: 'navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: HomeOutlinedIcon,
            url: '/dashboard'
          },
          {
            id: 'academic-calendar',
            title: 'Kalender Akademik',
            type: 'item',
            icon: CalendarMonthOutlinedIcon,
            url: '/academic-calendar'
          },
          {
            id: 'schedule',
            title: 'Jadwal',
            type: 'item',
            icon: EditCalendarOutlinedIcon,
            url: '/schedule'
          },
          {
            id: 'schedule-student',
            title: 'Jadwal Mahasiswa',
            type: 'item',
            icon: TodayOutlinedIcon,
            url: '/schedule-student'
          },
          {
            id: 'lecture-subject',
            title: 'Dosen Pengajar',
            type: 'item',
            icon: BookOutlinedIcon,
            url: '/lecture-subject'
          },
          {
            id: 'subject',
            title: 'Mata Kuliah',
            type: 'item',
            icon: MenuBookOutlinedIcon,
            url: '/subject'
          },
          {
            id: 'faculty',
            title: 'Fakultas',
            type: 'item',
            icon: AccountBalanceOutlinedIcon,
            url: '/faculty'
          },
          {
            id: 'room',
            title: 'Ruangan',
            type: 'item',
            icon: MeetingRoomOutlinedIcon,
            url: '/room'
          },
          {
            id: 'graduation-level',
            title: 'Jenjang',
            type: 'item',
            icon: SchoolOutlinedIcon,
            url: '/graduation-level'
          },
          {
            id: 'grade-code',
            title: 'Kode Nilai',
            type: 'item',
            icon: GradeOutlinedIcon,
            url: '/grade-code'
          }
        ]
      },
      {
        id: 'survey',
        type: 'group',
        title: 'PKKMB',
        children: [
          {
            id: 'survey-module',
            title: 'Survei',
            type: 'item',
            icon: BallotOutlinedIcon,
            url: '/survey-module'
          },
          {
            id: 'survey-period',
            title: 'Periode',
            type: 'item',
            icon: AssessmentOutlinedIcon,
            url: '/survey-period'
          },
          {
            id: 'question',
            title: 'Pertanyaan',
            type: 'item',
            icon: StickyNote2OutlinedIcon,
            url: '/question'
          },
          {
            id: 'question-answer',
            title: 'Hasil Evaluasi',
            type: 'item',
            icon: QuestionAnswerOutlinedIcon,
            url: '/question-answer'
          },
          {
            id: 'attendance',
            title: 'Kehadiran',
            type: 'item',
            icon: ContactMailOutlinedIcon,
            url: '/attendance'
          }
        ]
      },
      {
        id: 'graduation',
        type: 'group',
        title: 'Wisuda',
        children: [
          {
            id: 'graduate-student',
            title: 'Mahasiswa Pascasarjana',
            type: 'item',
            icon: AssignmentIndOutlinedIcon,
            url: '/graduate-student'
          },
          {
            id: 'graduation-batch',
            title: 'Gelombang Wisuda',
            type: 'item',
            icon: CastForEducationOutlinedIcon,
            url: '/graduation-batch'
          },
          {
            id: 'redeem',
            title: 'Redeem',
            type: 'item',
            icon: InventoryOutlinedIcon,
            url: '/redeem'
          }
        ]
      },
      {
        id: 'setting',
        type: 'group',
        title: 'Pengaturan',
        children: [
          {
            id: 'menu',
            title: 'Menu',
            type: 'collapse',
            icon: WidgetsOutlinedIcon,
            children: [
              {
                id: 'menu-group',
                title: 'Grup Menu',
                type: 'item',
                url: '/menu/group'
              },
              {
                id: 'menu-list',
                title: 'List Menu',
                type: 'item',
                url: '/menu/list'
              }
            ]
          },
          {
            id: 'user-academic',
            title: 'Pengguna Akademik',
            type: 'collapse',
            icon: ManageAccountsOutlinedIcon,
            children: [
              {
                id: 'user-type',
                title: 'Tipe Pengguna',
                type: 'item',
                url: '/academic/user-type'
              },
              {
                id: 'menu-type',
                title: 'Menu Pengguna',
                type: 'item',
                url: '/academic/menu-type'
              }
            ]
          },
          {
            id: 'payment-method',
            title: 'Metode Pembayaran',
            type: 'item',
            icon: PaymentOutlinedIcon,
            url: '/payment-method'
          },
          {
            id: 'rule',
            title: 'Peraturan',
            type: 'item',
            icon: RuleOutlinedIcon,
            url: '/rule'
          },
          {
            id: 'letter',
            title: 'Surat',
            type: 'item',
            icon: AttachEmailOutlinedIcon,
            url: '/letter'
          },
          {
            id: 'article',
            title: 'Artikel',
            type: 'item',
            icon: ArticleOutlinedIcon,
            url: '/article'
          },
          {
            id: 'faq',
            title: 'FAQ',
            type: 'item',
            icon: QuizOutlinedIcon,
            url: '/faq'
          }
        ]
      }
    ];
  }
};

export default {
  items: renderMenuItems()
};
